import { bindable, inject } from 'aurelia-framework';
import { Router }           from 'aurelia-router';

@inject(Router)
export class EditRecord {

    @bindable route;
    @bindable params = {};

    /**
     * Constructor
     *
     * @param router
     */
    constructor(router) {
        this.router = router;
    }

    /**
     * Navigates to a given route
     *
     * @param route
     */
    navigate(route) {
        this.router.navigateToRoute(route, this.params);
    }

}
