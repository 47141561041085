import { bindable, containerless } from 'aurelia-framework';
import { Smiley }                  from 'resources/elements/html-elements/smiley-icons/smiley';

@containerless()
export class HappySmileyCustomElement extends Smiley {
    /**
     * Value of this smiley
     *
     * @type {number}
     */
    value = 1;

    @bindable highlightColor = '#4caf50';
}
