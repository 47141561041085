import { inject, useView }      from 'aurelia-framework';
import { AppContainer }         from 'resources/services/app-container';
import { DeleteResourceDialog } from './delete-resource-dialog';
import { DialogController }     from 'aurelia-dialog';

@useView('resources/elements/html-elements/dialogs/custom-dialog.html')
@inject(AppContainer, DialogController)
export class DeleteSelectedResourcesDialog extends DeleteResourceDialog {

    defaultSettings = {
        title:         null,
        body:          null,
        resource:      null,
        buttons:       false,
        customButtons: [
            {
                class:  'btn-link',
                action: this.cancel.bind(this),
                label:  'dialog.button.keep-records',
            },
            {
                class:  'btn-danger',
                action: this.submit.bind(this),
                label:  'dialog.button.delete-records',
            },
        ],
    };

    /**
     * Handles activate event
     */
    activate(settings) {
        super.activate(settings);

        this.settings.body  = this.appContainer.i18n.tr('dialog.body.delete-records');
        this.settings.title = this.appContainer.i18n.tr('dialog.title.delete-records');
    }

}
