import { inject, useView }  from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { CustomDialog }     from 'resources/elements/html-elements/dialogs/custom-dialog';
import { DialogController } from 'aurelia-dialog';

@useView('resources/elements/html-elements/dialogs/custom-dialog.html')
@inject(AppContainer, DialogController)
export class DeleteResourceDialog extends CustomDialog {

    defaultSettings = {
        title:         null,
        body:          null,
        resource:      null,
        buttons:       false,
        customButtons: [
            {
                class:  'btn-link',
                action: this.cancel.bind(this),
                label:  'dialog.button.keep-record',
            },
            {
                class:  'btn-danger',
                action: this.submit.bind(this),
                label:  'dialog.button.delete-record',
            },
        ],
    };

    /**
     * Handles activate event
     */
    activate(settings) {
        super.activate(settings);

        this.settings.resource = this.appContainer.i18n.tr(settings.resource).toLowerCase();
        this.settings.body     = this.appContainer.i18n.tr('dialog.body.delete-resource');
        this.settings.title    = this.appContainer.i18n.tr('dialog.title.delete-resource', { resource: this.settings.resource, interpolation: { escapeValue: false } });
    }

    /**
     * Submits modal
     */
    submit() {
        let method     = this.settings.action.method;
        let parameters = this.settings.action.parameters;

        return method(...parameters).then((response) => {
            let notifierType = response.status === true ? 'successNotice' : 'dangerNotice';

            this.appContainer.notifier[notifierType](response.message);

            return this.dialogController.ok(response);
        });
    }

}
