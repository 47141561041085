import { bindable, computedFrom, containerless, inject } from 'aurelia-framework';
import { AppContainer }                                  from 'resources/services/app-container';

@containerless()
@inject(AppContainer)
export class Smiley {
    /**
     * Value of this smiley
     *
     * @type {number}
     */
    value     = NaN;
    observers = [];

    @bindable model;
    @bindable checked  = false;
    @bindable hovered  = false;
    @bindable readonly = false;

    @bindable defaultColor   = '#999999';
    @bindable highlightColor = '#000000';

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Color accessor
     *
     * @returns {string}
     */
    @computedFrom('checked', 'hovered')
    get color() {
        return this.checked || this.hovered ? this.highlightColor : this.defaultColor;
    }

    /**
     * Handles bind event
     */
    bind() {
        if (!this.readonly) {
            this.subscribeObservers();

            this.checked = this.model.value === this.value;
        }
    }

    /**
     * Handles unbind event
     */
    unbind() {
        if (!this.readonly) {
            this.disposeObservers();
        }
    }

    /**
     * Handles click event
     */
    clicked() {
        if (!this.readonly) {
            this.model.value = this.value;
        }
    }

    /**
     * Handles mouse move event
     */
    mouseMove() {
        if (!this.readonly) {
            this.hovered = true;
        }
    }

    /**
     * Handles mouse left event
     */
    mouseLeft() {
        if (!this.readonly) {
            this.hovered = false;
        }
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes `model.value` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model, 'value')
                .subscribe(this.modelChanged.bind(this)),
        );
    }

    /**
     * Disposes observers
     */
    disposeObservers() {
        while (this.observers.length) {
            this.observers.pop().dispose();
        }

        this.observers.length = 0;
    }

    /**
     * Handles model change
     *
     * @param newValue
     * @param oldValue
     */
    modelChanged(newValue, oldValue) {
        this.checked = newValue === this.value;
    }
}
