import { bindable, containerless } from 'aurelia-framework';

@containerless()
export class PanelGroup {

    @bindable panelGroupClass = 'col-md-12';
    @bindable expanded        = true;

    @bindable headerTitle;
    @bindable headerSubtitle;
    @bindable collapseGroup;
    @bindable collapseClass;

    /**
     * Constructor
     *
     * @param i18n
     */
    constructor(i18n) {
        this.collapseGroup = 'collapse-group-main';
    }

    /**
     * Handles `attached` event
     */
    attached() {
        if (this.expanded === true) {
            this.collapseClass = 'show';
        }
    }

}
