import { bindable, bindingMode, inject } from 'aurelia-framework';
import { BindingEngine }                 from 'aurelia-binding';

@inject(Element, BindingEngine)
export class DatatableRowCheckbox {

    @bindable({defaultBindingMode: bindingMode.twoWay}) checked;
    @bindable({defaultBindingMode: bindingMode.twoWay}) value;
    @bindable({defaultBindingMode: bindingMode.twoWay}) model;
    @bindable extraClasses;
    @bindable attributes;
    @bindable checkboxElement;

    isChecked = false;

    /**
     * Constructor
     *
     * @param element
     * @param bindingEngine
     */
    constructor(element, bindingEngine) {
        this.element       = element;
        this.bindingEngine = bindingEngine;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.evaluateChecked();
        this.subscribeObservers();
    }

    /**
     * Handles unbind event
     */
    unbind() {
        this.subscription.dispose();
    }

    /**
     * Evaluates checked attribute of checkbox element
     */
    evaluateChecked() {
        this.isChecked = this.checkboxElement.checked === true;
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        let observerMethod = isArray(this.checked) ? 'collectionObserver' : 'propertyObserver';
        let observable     = isArray(this.checked) ? [this.checked] : [this, 'checked'];

        this.subscription = this.bindingEngine[observerMethod](...observable).subscribe(() => this.evaluateChecked());
    }

    /**
     * Disposes observers
     */
    disposeObservers() {
        this.subscription.dispose();
    }
}
