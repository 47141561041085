import { bindable, customElement, inject } from 'aurelia-framework';
import { Downloader }                      from 'resources/services/downloader';
import Cropper                             from 'cropperjs';

@inject(Downloader)
@customElement('image-cropper')
export class ImageCropper {

    @bindable image;
    @bindable croppedImage;

    element;

    /**
     * Constructor
     *
     * @param downloader
     */
    constructor(downloader) {
        this.downloader = downloader;
    }

    /**
     * Bind event
     */
    bind() {
        this.cropper = new Cropper(
            this.element,
            {
                //aspectRatio:        16 / 9,
                //initialAspectRatio: 16 / 9,
                highlight: true,
                viewMode:  1,
                guides:    false,
                crop(event) {
                    //
                },
            },
        );
    }

    /**
     * Returns the image on its current state
     *
     * @returns {string}
     */
    getImageDataURL() {
        return this.cropper.getCroppedCanvas().toDataURL('image/png');
    }

}
