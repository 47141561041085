import { containerless, customElement } from 'aurelia-framework';

@containerless()
@customElement('accordion')
export class AccordionCustomElement {

    constructor() {
        this.sections = [];
    }

}
