export class IntToBooleanValueConverter {

    toView(value) {
        this.isValueBoolean = this.checkBool(value);

        return this.isValueBoolean ? value : !!value;
    }

    fromView(value) {
        return this.isValueBoolean ? value : +value;
    }

    checkBool(bool) {
        return typeof bool === 'boolean' || (typeof bool === 'object' && bool !== null && typeof bool.valueOf() === 'boolean');
    }

    checkInt(int) {
        return (typeof int === 'number') && Math.floor(int) === int;
    }
}
