import { bindable, containerless } from 'aurelia-framework';
import { Smiley }                  from 'resources/elements/html-elements/smiley-icons/smiley';

@containerless()
export class SadSmileyCustomElement extends Smiley {
    /**
     * Value of this smiley
     *
     * @type {number}
     */
    value = -1;

    @bindable highlightColor = '#f44336';
}
