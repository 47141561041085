import { inject, useView }  from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { CustomDialog }     from 'resources/elements/html-elements/dialogs/custom-dialog';
import { DialogController } from 'aurelia-dialog';

@useView('resources/elements/html-elements/dialogs/custom-dialog.html')
@inject(AppContainer, DialogController)
export class InfoDialog extends CustomDialog {

    defaultSettings = {
        title:         null,
        titleClass:    'text-info',
        body:          null,
        buttons:       false,
        customButtons: [],
        okButton:      {
            class:  'btn-primary',
            action: this.submit.bind(this),
            label:  'dialog.button.ok',
        },
    };

    /**
     * Handles activate event
     */
    activate(settings) {
        super.activate(settings);

        this.settings.customButtons.push(this.settings.okButton);
    }

}
