import { inject }           from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { DangerAlertMessage }   from 'resources/services/danger-alert-message';
import { SuccessAlertMessage }  from 'resources/services/success-alert-message';
import { WarningAlertMessage }  from 'resources/services/warning-alert-message';
import { DialogController } from 'aurelia-dialog';

@inject(AppContainer, DialogController)
export class CustomDialog {

    defaultSettings = {
        title:         null,
        body:          null,
        buttons:       false,
        customButtons: [],
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     */
    constructor(appContainer, dialogController) {
        this.appContainer     = appContainer;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     */
    activate(settings) {
        this.settings = $.extend(true, {}, this.defaultSettings, settings);
    }

    /**
     * Submits modal
     */
    submit() {
        return this.dialogController.ok();
    }

    /**
     * Cancels dialog
     */
    cancel() {
        return this.dialogController.cancel();
    }

    /**
     * Returns a new alert message
     *
     * @param status
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     * @param icon
     *
     * @returns {*}
     */
    alertMessage(status, message, details, scrollTop, css, icon) {
        return status === true
            ? this.successAlertMessage(message, details, scrollTop, css, icon)
            : this.dangerAlertMessage(message, details, scrollTop, css, icon);
    }

    /**
     * Returns a new success alert message
     *
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     * @param icon
     *
     * @returns {SuccessAlertMessage}
     */
    successAlertMessage(message, details, scrollTop, css, icon) {
        return new SuccessAlertMessage(
            message,
            details,
            scrollTop,
            css,
            icon,
        );
    }

    /**
     * Returns a new warning alert message
     *
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     * @param icon
     *
     * @returns {WarningAlertMessage}
     */
    warningAlertMessage(message, details, scrollTop, css, icon) {
        return new WarningAlertMessage(
            message,
            details,
            scrollTop,
            css,
            icon,
        );
    }

    /**
     * Returns a new danger alert message
     *
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     * @param icon
     *
     * @returns {DangerAlertMessage}
     */
    dangerAlertMessage(message, details, scrollTop, css, icon) {
        return new DangerAlertMessage(
            message,
            details,
            scrollTop,
            css,
            icon,
        );
    }

}
