import { bindable, inject } from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { createTree }       from 'jquery.fancytree';

import 'jquery.fancytree/dist/modules/jquery.fancytree.edit';
import 'jquery.fancytree/dist/modules/jquery.fancytree.filter';
import 'jquery.fancytree/dist/modules/jquery.fancytree.persist';

@inject(AppContainer)
export class FancyTree {

    defaults = {
        id:             'fancy-tree-' + (Math.floor(Math.random() * 65000)),
        controlButtons: true,
        shouldPersist:  false, // override this if you wish to persist the tree's state in localStorage
        checkbox:       false,
        extensions:     [],
        renderNode:     (event, data) => {
            let node = data.node;

            $(data.node.span).attr('title', node.title);
        },
    };

    @bindable options   = {};
    @bindable maxHeight = null;

    eventListeners = [];
    treeContainer  = null;
    tree           = null;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Handles attached event
     */
    attached() {
        this.subscribeEventListeners();

        this.createElement();
    }

    /**
     * Handles detached event
     */
    detached() {
        this.disposeEventListeners();
    }

    /**
     * Selects nodes
     *
     * @param nodes
     */
    selectNodes(nodes) {
        let nodeSingleSelection = !Array.isArray(nodes);

        nodes = Array.isArray(nodes) ? nodes : [nodes];

        $(this.treeContainer).fancytree('getTree').visit(node => {
            let nodeKey  = parseInt(node.key, 10);
            let selected = nodes.indexOf(nodeKey) >= 0;

            node.setSelected(selected);

            // if the node is selected and the selection mode is single then expand all parents from root up to selected node
            if (selected && nodeSingleSelection) {
                node.getParentList().forEach(parentNode => parentNode.setExpanded(true));
            }
        });
    }

    /**
     * Function that clears all nodes of having the checkbox = true parameter if settings demand it
     * (clears children recursivelly as well)
     *
     * @param settings
     * @param nodes
     */
    clearNodesListOfCheckbox(settings, nodes) {
        nodes.forEach(node => {
            node.checkbox = settings.checkbox ? node.checkbox : false;

            if (node.children) {
                this.clearNodesListOfCheckbox(settings, node.children);
            }
        });
    }

    /**
     * Creates element
     */
    createElement() {
        this.settings = $.extend({}, this.defaults, this.options);

        if (this.settings.shouldPersist === true) {
            this.setupPersistence();
        }

        let activateCallback = this.settings.activate;

        if (activateCallback instanceof Function) {
            this.settings.activate = (event, data) => {
                activateCallback(event, data);
            };
        }

        this.fetchTree().then(response => {
            this.settings.source = response;

            // fancy tree sometimes does not feel like obeying the setting.checkbox if response has nodes with checkbox = true;
            // this forces all nodes to be false if this.settings.checkbox is falsy
            this.clearNodesListOfCheckbox(this.settings, this.settings.source);

            this.tree = createTree(this.treeContainer, this.settings);

            if (this.settings.initial_nodes) {
                this.selectNodes(this.settings.initial_nodes);
            }

            if (this.maxHeight) {
                $(this.treeContainer).find('.ui-fancytree').css('max-height', this.maxHeight);
            }
        });
    }

    /**
     * Fetches tree
     */
    fetchTree() {
        if (this.options.repository && this.options.repository.source && this.options.repository.method) {
            let repository = this.options.repository.source;
            let method     = this.options.repository.method;
            let parameters = null;

            if (this.options.repository.parameters) {
                parameters = this.options.repository.parameters();
            }

            return repository[method](parameters);
        }

        return Promise.resolve(this.options.source);
    }

    /**
     * Reloads tree
     */
    reload() {
        this.fetchTree().then(response => $(this.treeContainer).fancytree('option', 'source', response));
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `reload-fancy-tree` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('reload-fancy-tree', tree => {
                if (tree.id === this.options.id) {
                    this.reload();
                }
            }),
        );
    }

    /**
     * Disposes event listeners
     */
    disposeEventListeners() {
        this.eventListeners.forEach(eventListener => eventListener.dispose());
        this.eventListeners.splice(0, this.eventListeners.length);
    }

    /**
     * Collapses every node
     */
    collapseAll() {
        this.tree.getRootNode().visit(node => node.setExpanded(false));
    }

    /**
     * Expands every node
     */
    expandAll() {
        this.tree.getRootNode().visit(node => node.setExpanded(true));
    }

    /**
     * Setups the tree's settings for persistence
     */
    setupPersistence() {
        this.settings.extensions.push('persist');

        this.settings.persist = {
            cookieDelimiter: '~',
            cookiePrefix:    'tree-persistence-' + this.settings.id + '-',
            cookie:          {
                raw:     false,
                expires: '',
                path:    '',
                domain:  '',
                secure:  false,
            },
            expandLazy:      true,
            expandOpts:      {
                noAnimation: true,
                noEvents:    true,
            },
            overrideSource:  true,
            store:           'local',
            types:           'active expanded focus selected',
        };
    }

}
