import { bindable, containerless } from 'aurelia-framework';

@containerless
export class SimpleTextarea {

    @bindable name;
    @bindable value;
    @bindable placeholder = '';
    @bindable isRequired  = true;
    @bindable isDisabled  = false;
    @bindable isReadonly  = false;
    @bindable columnSize  = 4;

}
