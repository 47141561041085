import { bindable, containerless } from 'aurelia-framework';
import { Smiley }                  from 'resources/elements/html-elements/smiley-icons/smiley';

@containerless()
export class NeutralSmileyCustomElement extends Smiley {
    /**
     * Value of this smiley
     *
     * @type {number}
     */
    value = 0;

    @bindable highlightColor = '#ffcd64';
}
