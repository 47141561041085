import { bindable, bindingMode, containerless, inject } from 'aurelia-framework';

@containerless
@inject(Element)
export class InputRadio {

    @bindable({defaultBindingMode: bindingMode.twoWay}) checked;
    @bindable({defaultBindingMode: bindingMode.twoWay}) value;
    @bindable({defaultBindingMode: bindingMode.twoWay}) model;
    @bindable extraClasses;
    @bindable attributes;
    @bindable ontoggle;
    @bindable name;

    /**
     * Constructor
     *
     * @param element
     */
    constructor(element) {
        this.element = element;
    }

    /**
     * Handles `checked` attribute change
     *
     * @param newValue
     * @param oldValue
     */
    checkedChanged(newValue, oldValue) {
        if (this.ontoggle instanceof Function) {
            this.ontoggle({
                id:      this.id,
                value:   this.value,
                checked: this.checked,
            });
        }
    }

}
