import { bindable, bindingMode, containerless, inject } from 'aurelia-framework';

@containerless
@inject(Element)
export class InputCheckbox {

    @bindable checkboxElement;

    @bindable({defaultBindingMode: bindingMode.twoWay}) checked;
    @bindable({defaultBindingMode: bindingMode.twoWay}) value;
    @bindable({defaultBindingMode: bindingMode.twoWay}) model;
    @bindable extraClasses;
    @bindable attributes;
    @bindable labelStyles;

    /**
     * Constructor
     *
     * @param element
     */
    constructor(element) {
        this.element = element;
    }

}
