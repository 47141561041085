import { bindable, containerless } from 'aurelia-framework';

@containerless
export class AureliaModal {

    @bindable settings;

    // default action
    @bindable action = () => Promise.resolve(true);

    /**
     * Handles bind event
     *
     * @param bindingContext
     */
    bind(bindingContext) {
        this.bindingContext = bindingContext;
    }

    /**
     * Handles attached event
     */
    attached() {
        document.getElementsByTagName('body').item(0).classList.add('modal-open');
    }

    /**
     * Handles detached event
     */
    detached() {
        if (!this.isChild && !$('ux-dialog-container').length) {
            document.getElementsByTagName('body').item(0).classList.remove('modal-open');
        }
    }

    /**
     * Submits modal
     */
    submit() {
        return this.action().then((status) => {
            if (status === true) {
                return this.bindingContext.dialogController.ok();
            }
        });
    }

    /**
     * Cancels modal
     */
    cancel() {
        return this.bindingContext.dialogController.cancel();
    }

}
