export class FormFieldPlaceholderValueConverter {

    /**
     * Performs the value conversion
     *
     * @param label
     * @param isRequired
     *
     * @returns {string|*}
     */
    toView(label, isRequired) {
        isRequired = (isRequired === null || typeof isRequired === 'undefined') ? true : isRequired;
        label      = isRequired ? label + ' *' : label;

        return label;
    }

}
