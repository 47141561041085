import { PLATFORM } from 'aurelia-pal';

export function configure(config) {
    config.globalResources(
        [
            PLATFORM.moduleName('./datatable-row-checkbox'),
            PLATFORM.moduleName('./form-validation-errors'),
            PLATFORM.moduleName('./input-checkbox'),
            PLATFORM.moduleName('./input-radio'),
            PLATFORM.moduleName('./simple/simple-select2'),
            PLATFORM.moduleName('./simple/simple-textarea'),
        ],
    );
}
