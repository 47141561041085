import { bindable, bindingMode } from 'aurelia-framework';

export class SchemaForm {

    @bindable schema;

    @bindable({defaultBindingMode: bindingMode.twoWay})
    formId;

    @bindable({defaultBindingMode: bindingMode.twoWay})
    model;

    @bindable({defaultBindingMode: bindingMode.twoWay})
    alert;

}
