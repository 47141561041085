import { bindable, bindingMode, computedFrom } from 'aurelia-framework';

export class Pagination {

    @bindable({defaultBindingMode: bindingMode.twoWay}) currentPage = 1;
    @bindable model;
    @bindable pageBlockSize                                         = 5;
    @bindable pageSize                                              = 10;
    @bindable pages                                                 = 0;

    currentBlockStartPageIndex = 0;

    @computedFrom('currentPage')
    get firstPageDisabled() {
        return this.currentPage <= 1;
    }

    @computedFrom('currentPage')
    get previousPageDisabled() {
        return this.currentPage <= 1;
    }

    @computedFrom('currentPage', 'pages')
    get nextPageDisabled() {
        return this.currentPage >= this.pages;
    }

    @computedFrom('currentPage', 'pages')
    get lastPageDisabled() {
        return this.currentPage >= this.pages;
    }

    /**
     * Handles bind event
     *
     * @param bindingContext
     */
    bind(bindingContext) {
        this.model = this.model || bindingContext;
    }

    /**
     * Returns minimum allowed page
     *
     * @param a
     * @param b
     * @returns {number}
     */
    minimumAllowedPage(a, b) {
        let page = Math.min(a, b);

        return Math.max(page, 1);
    }

    /**
     * Updates page block
     */
    updatePageBlocks() {
        let pageBlockSize = parseInt(this.pageBlockSize || this.pages, 10);
        let blockIndex    = 0;

        if (this.currentPage && pageBlockSize) {
            blockIndex = Math.ceil(this.currentPage / pageBlockSize) - 1;
        }

        if (blockIndex) {
            this.currentBlockStartPageIndex = (blockIndex * pageBlockSize) + 1;
        } else {
            this.currentBlockStartPageIndex = 1;
        }

        this.numberOfVisiblePages = this.minimumAllowedPage(pageBlockSize, this.pages - this.currentBlockStartPageIndex + 1);
    }

    /**
     * Refreshes view model
     */
    refresh() {
        if (this.model.load) {
            this.model.load();
        } else {
            throw new Error(`${this.model.constructor.name} does not contain a 'load' function.`);
        }
    }

    /**
     * Goes to a given page
     *
     * @param page
     */
    goToPage(page) {
        if (page > 0 && page <= this.pages && page !== this.currentPage) {
            this.currentPage = page;
            this.model.page  = page;

            this.refresh();
        }
    }

    /**
     * Handles current page changing
     */
    currentPageChanged() {
        this.updatePageBlocks();
    }

    /**
     * Handles pages changing
     */
    pagesChanged() {
        this.updatePageBlocks();
    }

}
