import { bindable, containerless } from 'aurelia-framework';

@containerless()
export class PanelHeader {

    @bindable headerTitle;
    @bindable headerSubtitle;
    @bindable collapseGroup;
    @bindable collapseClass;
    @bindable ariaExpanded;

    /**
     * Handles `attached` event
     */
    attached() {
        if (this.ariaExpanded === 'false') {
            this.collapseClass = 'collapsed';
        }
    }

}
